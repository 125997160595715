import { motion } from 'framer-motion';
import  './about.scss';

const variants = {
    open: {
      transition: {
        staggerChildren:0.2,

      },
    },
    closed: {
      transition: {
        staggerChildren:0.05,
        staggerDirection:-1,

      },
    },
  };
  const itemVariants = {
    open: {
      y:0,
      opacity:1,
    },
    closed: {
      y:50,
      opacity:0,
    },
  };
export default function Contact() {
  const list = [
    {
      id:1,
      title: 'React',
    },
    {
      id:2,
      title: 'JavaScript (ES6+)',
    },
    
    {
      id:4,
      title: 'WordPress',
    },
    {
      id:5,
      title: 'PHP',
    },
    {
      id:6,
      title: 'HTML',
    },
    {
      id:7,
      title: 'SASS',
    },
    {
      id:8,
      title: 'CSS',
    },
    {
      id:9,
      title: 'MySQL'
    }
  ]
  return (
    <div id='about' className='about'>
      <div className="left">
        <div className="container">
          <motion.div  variants={variants} className="cart">

            {
              list.map((item)=>(
                <motion.div  variants={itemVariants} whileHover={{scale:1.1}}  className="item" key={item.id}>
                  {item.title}
              </motion.div >
              ))
            }          
            </motion.div>
          </div>
      </div>
      <div className="right">
        <h2>私について</h2>
            <div className="centerTxt">
            フロントエンド開発を専門とし、創造性と正確さを融合させ、シームレスなユーザー体験を作り上げます。
            チャレンジ精神旺盛で、コンセプトを魅力的なデジタルの現実へと昇華させることに喜びを感じ、さまざまなプロジェクトで常にスキルを磨いています。
            イノベーションにコミットし、ユーザー中心の魅力的なソリューションを創造します。
            </div>
            <a href="#portfolio">
          <img src="assets/down.png" alt="" />
        </a>
      </div>
    </div>
  )
}
