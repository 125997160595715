import './services.scss'
import {
    motion,
    // useInView
}
    from 'framer-motion'
import people from '../../assets/people.webp'
import { useRef } from 'react'
const variants = {
    initial: {
        x: -500,
        y: 100,
        opacity: 0,
    },
    animate: {
        x: 0,
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
            staggerChildren: 0.1,
        }
    },
}
const Services = () => {

    const ref = useRef()

    // const isInView = useInView(ref, { margin: "-100px" })
    return (
        <motion.div
            className='services'
            variants={variants}
            initial='initial'
            // animate='animate'
            whileInView='animate'
            ref={ref}
        // animate={isInView && "animate"}
        >
            <motion.div className="textContainer" variants={variants} initial='initial' animate='animate'>
                <p>あなたのブランドが成長し、
                    <br />前進する手助けをすることに重点を置いています。
                </p>
                <hr />
            </motion.div>
            <motion.div className="titleContainer" variants={variants} initial='initial' animate='animate'>
                <div className="title">
                    <img src={people} alt="" />
                    <h1>

                        <motion.b whileHover={{ color: "orange" }} >ユニークな </motion.b >アイデア
                    </h1>
                </div>
                <div className="title">
                    <h1>
                        <motion.b whileHover={{ color: "orange" }}>あなたの </motion.b >ビジネスのために
                    </h1>
                    {/* <button>やってること</button> */}
                </div>
            </motion.div>
            <motion.div className="listContainer" variants={variants} initial='initial' animate='animate'>
                <motion.div className="box" whileHover={{ background: "lightgray", color: 'black' }}>
                    <h2>Web design</h2>
                    <p>魅力的でユーザー中心のウェブサイトデザインで、オンラインプレゼンスを高めましょう。視覚的に美しく、直感的に操作できる、印象に残るウェブサイトの制作を得意としています。
                        コンセプトから実行まで、私のデザインの専門知識はあなたのブランドに命を吹き込み、シームレスで魅力的なユーザー体験をお約束します。
                    </p>
                    
                </motion.div>
                <motion.div className="box" whileHover={{ background: "lightgray", color: 'black' }}>
                    <h2>E-commerce</h2>
                    <p>包括的なEコマース・ソリューションで、オンライン・セールスに革命を起こしましょう。ユーザーフレンドリーなインターフェイスから安全な決済ゲートウェイまで、シームレスで効果的なオンラインショッピング体験の創造を得意としています。
                        私のEコマース専門知識は、あなたのビジネスがデジタル市場で成功し、ブラウジングからチェックアウトまで手間のかからない旅を顧客に提供することを保証します。
                    </p>
                    
                </motion.div>
                <motion.div className="box" whileHover={{ background: "lightgray", color: 'black' }}>
                    <h2>Website application</h2>
                    <p>お客様独自のニーズに合わせた革新的なウェブアプリケーションのパワーを活用しましょう。機能性とユーザーとのインタラクションを高める、ダイナミックでレスポンシブなウェブソリューションを得意としています。
                        カスタムアプリケーションであれ、洗練されたプラットフォームであれ、お客様のアイデアを高性能でユーザーフレンドリーなウェブ体験に変えます。
                    </p>
                    
                </motion.div>
                {/* <motion.div className="box" whileHover={{ background: "lightgray", color: 'black' }}>
                    <h2>Branding</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                        Odit impedit, sit amet libero reiciendis recusandae id sequi architecto
                        necessitatibus aperiam? Officia cupiditate quod harum quasi dolorem eos
                        illum modi libero.
                    </p>
                    
                </motion.div> */}
            </motion.div>
        </motion.div>

    )
}

export default Services