import "./hero.scss"
import {motion} from 'framer-motion'
import heroImg from "../../assets/hero.png"
import scroll from "../../assets/scroll.png"

const textVariants = {
    initial:{
        x:-500,
        opacity:0,
    },
    animate:{
        x:0,
        opacity:1,
        transition:{
            duration:1,
            staggerChildren: 0.1,
        }
    },
    scrollButton:{
        opacity:0,
        y:10,
        transition:{
            duration:2,
            repeat: Infinity
        }
    }
}
const sliderVariants = {
    initial:{
        x:0,

    },
    animate:{
        x:"-420%",
        transition:{
            duration:20,
            repeat:Infinity,
            repeatType:'mirror'
        }
    },
    
}
const Hero = () => {
    return (
        <div className="hero">
            <div className="wrapper">
                <motion.div className="textContainer" variants={textVariants} initial="initial" animate="animate">
                    <motion.h2 variants={textVariants}>K.S</motion.h2>
                    <motion.h1 variants={textVariants}>フロントエンドエンジニア</motion.h1>
                    <motion.div variants={textVariants} className="buttons">
                    <a href="#連絡先"><motion.button variants={textVariants}>最新作を見る</motion.button></a>
                        <a href="#ポートフォリオ"><motion.button variants={textVariants}>連絡先へ</motion.button></a>
                    </motion.div>
                    <motion.img variants={textVariants} src={scroll} alt="" animate="scrollButton" />
                </motion.div>
            </div>
            <motion.div className="slidingTextContainer" variants={sliderVariants} initial="initial" animate="animate">
                フロントエンドエンジニア　デザイナー
            </motion.div>
            <div className="imageContainer">
                <img src={heroImg} alt="" />
            </div>
        </div>
    )
}

export default Hero