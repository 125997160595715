import "./app.scss"
import Hero from "./components/hero/Hero";
import Navbar from "./components/navbar/Navbar";
import Parallax from "./components/parallax/Parallax";
import Services from "./components/services/Services";
import Portfolio from "./components/portfolio/Portfolio";
import Contact from "./components/contact/Contact";
import About from "./components/about/About"
function App() {
  return (
    <div>
      <section id="ホームページ">
        <Navbar />
        <Hero />

      </section>
      <section id="サービス">
        <Parallax type="services" />
      </section>
      <section >
        <Services />
      </section>
      <section id="ポートフォリオ">
        <Parallax type="Portfolio" />
      </section>
      <Portfolio />
      <section id="連絡先">
        <Contact/>
      </section>
      <section id="私について">
        <About/>
      </section>
    </div>

  );
}

export default App;
