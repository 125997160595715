import { useRef } from 'react'
import './parallax.scss'
import {motion, useScroll, useTransform} from 'framer-motion'
import sun from'../../assets/sun.png'
import planets from'../../assets/planets.png'

const Parallax = ({type}) => {
    const ref = useRef();

    const {scrollYProgress} = useScroll({
        target:ref,
        offset:["start start", "end start"]
    })

    const scrollYBg = useTransform(scrollYProgress, [0,1],["0%", "100%"])
    const scrollYTxt = useTransform(scrollYProgress, [0,1],["0%", "500%"])
  return (
    <div ref={ref} className="parallax" style={{
        background:
          type === "services"
            ? "linear-gradient(180deg, #111132, #0c0c1d)"
            : "linear-gradient(180deg, #111132, #505064)",
      }}>
        <motion.h1 style={{y:scrollYTxt}}>
            {type==="services" ?"サービス" : "ポートフォリオ"}
        </motion.h1>
        <motion.div  className="mountains">

        </motion.div>
        <motion.div  style={{y:scrollYBg,
            backgroundImage:`url(${type ==="services"? planets : sun})`
            }}  className="planets">

        </motion.div>
        <motion.div style={{x:scrollYBg}} className="stars">

        </motion.div>
    </div>
  )
}

export default Parallax