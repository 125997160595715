import { useRef } from "react";
import "./portfolio.scss";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import insurance from '../../assets/insurance.png'
import ecommerce from '../../assets/ecommerce.png'

const items = [
  {
    id: 1,
    title: "Web application",
    img: insurance,
    link: "https://kasaihoken-setsuyaku.com/",
    desc: "保険会社のためにダイナミックなウェブアプリを制作し、ユーザーが火災保険の見積もりを迅速に行えるようにした。このユーザーフレンドリーなプラットフォームは、シームレスで効率的な体験を保証し、迅速かつ正確な見積もりで貴重な資産を保護するプロセスを簡素化します。",
  },
  {
    id: 2,
    title: "Ecommerce",
    img: ecommerce,
    link: "https://www.rakuten.ne.jp/gold/tsukijiwadatsumi/",
    desc: "e-commerce websiteのコーディング、デザイン、メンテナンスを指揮。堅牢で視覚的に魅力的なプラットフォームを保証し、最適なパフォーマンスのために継続的なサポートを提供。",
  },
  {
    id: 3,
    title: "通信系システム再構築",
    img: "https://images.pexels.com/photos/6894528/pexels-photo-6894528.jpeg?auto=compress&cs=tinysrgb&w=1600&lazy=load",
    link: "",
    desc: "大手流通企業向け人事基幹システムの再構築プロジェクトに参画。",
  },
  {
    id: 4,
    title: "人事基幹システム再構築",
    img: "https://images.pexels.com/photos/18540208/pexels-photo-18540208/free-photo-of-wood-landscape-water-hill.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    link: "",
    desc: "大手流通企業向け人事基幹システムの再構築プロジェクトに参画。",
  },
];

const Single = ({ item }) => {
  const ref = useRef();

  const { scrollYProgress } = useScroll({
    target: ref,
  });

  const y = useTransform(scrollYProgress, [0, 1], [-300, 300]);

  return (
    <section >
      <div className="container">
        <div className="wrapper">
          <div className="imageContainer" ref={ref}>
            <img src={item.img} alt="" />
          </div>
          <motion.div className="textContainer" style={{y}}>
            <h2>{item.title}</h2>
            <p>{item.desc}</p>
            {item.link &&<a href={item.link} target="_blank" rel="noopener noreferrer"><button>ページへ</button></a>  }
          </motion.div>
        </div>
      </div>
    </section>
  );
};

const Portfolio = () => {
  const ref = useRef();

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "start start"],
  });

  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
  });

  return (
    <div className="portfolio" ref={ref}>
      <div className="progress">
        <h1>Featured Works</h1>
        <motion.div style={{ scaleX }} className="progressBar"></motion.div>
      </div>
      {items.map((item) => (
        <Single item={item} key={item.id} />
      ))}
    </div>
  );
};

export default Portfolio;