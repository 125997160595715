import "./navbar.scss"
import { motion } from "framer-motion"
import website from "../../assets/www.png"

import Sidebar from "../sidebar/Sidebar"
const Navbar = () => {
  return (
    <div className="navbar">
      {/* sidebar */}
      <Sidebar/>
      <div className="wrapper">
        <motion.span initial={{ opacity: 0, scale: 0.5 }} animate={{ opacity: 1, scale: 1 }} transition={{duration:0.5}}>
          ピーシーネット(株)開発部
        </motion.span>
        <div className="social">
          <a href="https://www.pcn-j.co.jp/" target="_blank" rel="noopener noreferrer"><img src={website} alt="" /></a>

        </div>
      </div>
    </div>
  )
}

export default Navbar