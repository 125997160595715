import {motion} from 'framer-motion';


const variants = {
    open: {
      transition: {
        staggerChildren:0.2,

      },
    },
    closed: {
      transition: {
        staggerChildren:0.05,
        staggerDirection:-1,

      },
    },
  };
  const itemVariants = {
    open: {
      y:0,
      opacity:1,
    },
    closed: {
      y:50,
      opacity:0,
    },
  };
const Links = ({ setOpen })  => {
    const items = [
        "ホームページ",
        "サービス ",
        "ポートフォリオ",
        "連絡先",
        "私について"
        
        
    ]

  return (
    <motion.div className='link' variants={variants} >{
        items.map(item => (
            <motion.a onClick={() => setOpen((prev) => !prev)} href={`#${item}`} key={item}  variants={itemVariants} whileHover={{scale:1.1}} whileTap={{scale:0.95}}>{item}</motion.a>
        )

        )
    }</motion.div>
  )
}

export default Links